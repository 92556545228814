import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2172.000000 1035.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1035.000000) scale(0.100000,-0.100000)">
          
          <path d="M2030 5070 l0 -2840 8735 0 8735 0 0 2840 0 2840 -8735 0 -8735 0 0
-2840z m7068 -48 l2 -1862 -2887 2 -2888 3 -3 1850 c-1 1018 0 1856 3 1863 3
10 586 12 2887 10 l2883 -3 3 -1863z m6872 993 l0 -645 -135 0 -135 0 0 50 0
49 -46 -49 c-138 -147 -314 -83 -385 140 -27 83 -37 277 -20 376 24 137 84
239 165 279 87 44 175 26 249 -50 l37 -38 0 266 0 267 135 0 135 0 0 -645z
m-4752 403 c17 -95 41 -234 53 -308 12 -74 25 -139 29 -143 4 -5 11 18 15 50
7 56 72 412 95 521 l12 52 179 0 c177 0 179 0 184 -22 4 -21 135 -1173 135
-1190 0 -5 -63 -8 -140 -8 -127 0 -140 2 -140 18 -1 46 -68 763 -71 767 -3 2
-40 -173 -84 -388 l-80 -392 -96 -3 -97 -3 -15 68 c-8 37 -46 213 -83 390 -63
296 -84 369 -84 296 0 -24 -32 -415 -56 -680 l-7 -73 -133 0 c-105 0 -134 3
-134 13 0 8 4 49 10 93 10 78 120 1094 120 1107 0 4 80 7 178 7 l179 0 31
-172z m6132 -77 c0 -55 3 -106 6 -115 4 -12 20 -16 55 -16 l49 0 0 -110 0
-110 -55 0 -55 0 0 -310 0 -310 -135 0 -135 0 0 310 0 310 -50 0 -50 0 0 110
0 110 50 0 50 0 0 108 c0 60 3 112 7 115 3 4 64 7 135 7 l128 0 0 -99z m-4953
-116 c20 -9 56 -37 80 -63 l43 -47 0 48 0 47 135 0 135 0 0 -420 0 -420 -135
0 -135 0 0 50 0 50 -52 -54 c-61 -63 -111 -82 -182 -72 -139 21 -226 194 -226
451 0 223 60 368 174 421 59 28 111 30 163 9z m1884 -16 c70 -33 122 -87 158
-163 38 -81 51 -147 51 -264 0 -231 -89 -378 -258 -428 -113 -32 -249 -7 -337
63 -104 82 -156 258 -134 451 21 189 98 306 231 354 79 28 214 22 289 -13z
m847 25 c21 -6 22 -10 22 -134 l0 -128 -79 -3 c-61 -3 -84 -8 -101 -24 -51
-46 -54 -64 -58 -327 l-4 -248 -134 0 -134 0 0 420 0 420 130 0 130 0 0 -66 0
-67 22 29 c45 60 84 97 123 115 44 21 50 21 83 13z m1338 -13 c22 -10 57 -38
77 -61 l37 -44 0 47 0 47 135 0 135 0 0 -410 c0 -225 0 -413 0 -417 0 -5 -61
-9 -135 -11 l-135 -3 0 47 0 46 -45 -47 c-74 -76 -148 -94 -231 -56 -55 25
-90 64 -125 137 -98 204 -68 551 57 678 63 63 153 82 230 47z m1414 3 c23 -9
58 -37 80 -63 l40 -46 0 48 0 47 135 0 135 0 0 -420 0 -420 -135 0 -135 0 0
51 0 52 -22 -29 c-80 -102 -163 -127 -263 -80 -62 28 -113 99 -147 201 -20 61
-23 90 -23 225 0 133 3 165 22 226 32 101 77 163 143 197 61 31 111 34 170 11z
m-4678 -114 c16 -52 44 -149 62 -215 l34 -120 11 55 c6 30 33 127 59 215 l48
160 138 3 c109 2 137 0 134 -10 -5 -12 -79 -257 -293 -968 l-61 -205 -124 -3
c-68 -1 -129 -1 -136 2 -8 3 4 57 42 182 l53 177 -131 406 c-71 223 -132 410
-135 414 -2 4 57 6 133 5 l138 -3 28 -95z m5416 -1232 c18 -18 14 -56 -7 -68
-27 -14 -8105 -14 -8132 0 -21 12 -25 50 -7 68 17 17 8129 17 8146 0z m-2778
-400 l0 -243 58 97 57 98 108 0 c59 0 107 -2 107 -5 0 -2 -38 -58 -85 -124
-47 -66 -85 -123 -85 -128 0 -5 38 -73 85 -153 47 -80 85 -148 85 -152 0 -5
-45 -8 -101 -8 l-100 0 -64 133 -64 132 0 -132 -1 -133 -90 0 -90 0 0 430 0
430 90 0 90 0 0 -242z m-2090 27 l0 -75 35 0 35 0 0 -85 0 -85 -35 0 -35 0 0
-200 0 -200 -90 0 -90 0 0 200 0 200 -35 0 -35 0 0 85 0 85 35 0 35 0 0 75 0
75 90 0 90 0 0 -75z m-929 -70 c16 -9 39 -30 49 -48 18 -29 20 -52 20 -279 l0
-248 -90 0 -90 0 0 194 c0 208 -3 221 -52 214 -41 -6 -48 -40 -48 -230 l0
-178 -90 0 -90 0 0 285 0 285 90 0 90 0 0 -37 0 -37 33 34 c56 58 121 74 178
45z m448 5 c55 -15 106 -57 134 -108 24 -45 47 -143 47 -204 l0 -28 -145 0
-145 0 0 -30 c0 -52 26 -90 61 -90 23 0 34 7 47 30 16 29 19 30 90 30 83 0 85
-3 51 -75 -55 -118 -190 -158 -305 -90 -75 44 -115 134 -116 265 0 47 4 103
10 125 37 134 154 209 271 175z m1727 -18 c51 -26 78 -56 106 -117 20 -43 23
-66 23 -160 0 -94 -3 -117 -23 -160 -39 -85 -97 -129 -186 -141 -101 -14 -194
35 -244 130 -25 47 -27 60 -27 171 1 135 16 183 79 244 63 61 187 77 272 33z
m572 -68 l3 -91 -49 5 c-41 3 -53 0 -78 -21 l-29 -25 -3 -181 -4 -181 -89 0
-89 0 0 285 0 285 85 0 85 0 0 -42 0 -43 27 34 c34 43 81 72 113 69 24 -3 25
-5 28 -94z m-1511 -68 c18 -76 34 -140 36 -142 2 -2 12 40 21 93 10 54 22 120
28 146 l10 48 76 -3 77 -3 26 -138 c24 -125 39 -177 39 -133 0 9 14 75 31 146
l31 130 90 0 c80 0 89 -2 84 -17 -3 -10 -42 -138 -87 -285 l-83 -268 -67 0
-68 0 -15 53 c-8 28 -25 93 -37 144 l-23 91 -35 -141 -36 -142 -69 0 -70 0
-83 270 c-45 149 -83 276 -83 283 0 10 21 12 88 10 l87 -3 32 -139z m-763
-731 c-6 -14 -35 -35 -48 -35 -16 0 -3 21 22 34 34 19 32 19 26 1z m-186 -45
c-4 -28 -3 -30 31 -32 l36 -1 -36 -4 c-36 -4 -36 -4 -52 -76 -9 -39 -13 -75
-11 -79 3 -4 16 -3 30 2 20 8 25 7 22 -2 -6 -17 -51 -33 -72 -25 -20 8 -20 42
-2 120 13 56 13 57 -8 58 -20 2 -20 2 2 6 12 3 22 12 22 22 0 18 18 41 33 41
5 0 7 -13 5 -30z m1660 0 c-4 -28 -3 -30 31 -32 l36 -1 -36 -4 c-36 -4 -36 -4
-52 -76 -9 -39 -13 -75 -11 -79 3 -4 16 -3 30 2 20 8 25 7 22 -2 -6 -17 -51
-33 -72 -25 -20 8 -20 42 -2 120 13 56 13 57 -8 58 -20 2 -20 2 2 6 12 3 22
12 22 22 0 18 18 41 33 41 5 0 7 -13 5 -30z m-2848 -47 c0 -10 -4 -24 -9 -31
-6 -10 -10 -9 -14 7 -14 54 -79 26 -97 -41 -8 -29 -9 -48 -1 -65 12 -26 21
-27 70 -13 45 13 51 6 12 -14 -43 -23 -98 -21 -111 3 -37 70 29 171 111 171
31 0 39 -4 39 -17z m181 -4 c34 -64 -32 -169 -106 -169 -75 0 -74 110 1 169
36 28 90 28 105 0z m129 -14 l0 -36 31 36 c33 38 75 47 86 19 3 -9 2 -45 -4
-80 -5 -36 -7 -66 -5 -69 2 -2 16 3 31 13 l26 16 -24 -27 c-14 -15 -35 -27
-48 -27 -19 0 -23 5 -23 33 0 17 5 50 10 72 14 60 -3 66 -49 17 -25 -26 -43
-56 -47 -78 -6 -33 -27 -54 -40 -41 -3 3 1 38 9 79 9 40 13 76 10 81 -3 5 -16
-2 -29 -14 -30 -28 -32 -14 -2 18 12 13 32 23 45 23 20 0 23 -5 23 -35z m240
0 l0 -36 31 36 c33 38 75 47 86 19 3 -9 2 -45 -4 -80 -5 -36 -7 -66 -5 -69 2
-2 16 3 31 13 l26 16 -24 -27 c-14 -15 -35 -27 -48 -27 -19 0 -23 5 -23 33 0
17 5 50 10 72 14 60 -3 66 -49 17 -25 -26 -43 -56 -47 -78 -6 -33 -27 -54 -40
-41 -3 3 1 38 9 79 9 40 13 76 10 81 -3 5 -16 -2 -29 -14 -30 -28 -32 -14 -2
18 12 13 32 23 45 23 20 0 23 -5 23 -35z m318 3 c4 -34 -30 -67 -80 -74 -40
-7 -46 -19 -24 -49 11 -16 18 -17 57 -7 53 13 61 9 20 -12 -43 -23 -98 -21
-111 3 -35 67 32 176 104 169 25 -2 32 -8 34 -30z m202 15 c0 -10 -4 -24 -9
-31 -6 -10 -10 -9 -14 7 -14 54 -79 26 -97 -41 -8 -29 -9 -48 -1 -65 12 -26
21 -27 70 -13 45 13 51 6 12 -14 -38 -20 -85 -20 -105 0 -33 32 -12 112 40
153 35 27 104 29 104 4z m279 -16 c-17 -62 -27 -127 -19 -132 4 -2 17 5 28 16
29 26 28 12 0 -18 -12 -13 -32 -23 -46 -23 l-24 0 7 80 c6 71 5 79 -9 74 -30
-12 -16 7 17 21 50 22 57 20 46 -18z m121 -2 l0 -36 31 36 c33 38 75 47 86 19
3 -9 2 -45 -4 -81 -5 -35 -6 -66 -3 -68 14 -9 48 26 53 54 4 17 22 47 40 67
30 33 41 38 90 42 32 2 57 1 57 -4 0 -24 -41 -207 -50 -223 -19 -37 -70 -71
-106 -71 -73 0 -98 44 -50 89 32 30 24 59 -10 36 -13 -8 -33 -15 -45 -15 -21
0 -22 3 -16 58 4 31 10 67 13 80 10 34 -14 27 -57 -18 -23 -24 -41 -54 -45
-76 -6 -33 -27 -54 -40 -41 -3 3 1 38 9 79 9 40 13 76 10 81 -3 5 -16 -2 -29
-14 -30 -28 -32 -14 -2 18 12 13 32 23 45 23 20 0 23 -5 23 -35z m572 -5 c4
-19 10 -53 13 -75 l7 -40 24 30 c14 17 32 51 42 78 11 32 22 47 34 47 22 0 23
8 -8 -113 -29 -115 -45 -147 -84 -171 -34 -19 -94 -22 -87 -3 2 6 20 12 41 12
41 0 51 10 67 70 5 22 13 49 16 59 3 12 -5 7 -24 -14 -39 -44 -60 -36 -68 27
-3 26 -9 61 -12 76 -5 27 -6 28 -24 11 -25 -22 -26 1 -2 28 26 29 56 19 65
-22z m319 19 c34 -64 -32 -169 -106 -169 -75 0 -74 110 1 169 36 28 90 28 105
0z m129 -11 c0 -18 -5 -51 -10 -72 -12 -54 -3 -63 30 -31 25 24 47 66 64 118
6 19 36 24 36 5 -1 -7 -7 -42 -15 -78 -7 -35 -11 -68 -8 -73 3 -4 16 -1 29 8
13 8 24 11 24 6 0 -15 -42 -41 -67 -41 -20 0 -23 5 -23 38 l0 37 -21 -33 c-21
-32 -58 -50 -78 -37 -13 8 -14 56 -1 109 11 50 4 61 -26 41 -13 -8 -24 -11
-24 -6 0 15 42 41 67 41 19 0 23 -5 23 -32z m591 11 c34 -64 -32 -169 -106
-169 -75 0 -74 110 1 169 36 28 90 28 105 0z m237 -17 l3 -32 33 35 c22 23 43
35 60 35 43 0 27 -32 -18 -36 -30 -2 -85 -72 -92 -116 -5 -28 -11 -38 -25 -38
-22 0 -23 5 -4 90 7 35 11 68 9 72 -3 4 -17 0 -32 -9 l-27 -17 25 27 c33 38
64 33 68 -11z m270 6 c4 -34 -30 -67 -80 -74 -40 -7 -46 -19 -24 -49 11 -16
18 -17 57 -7 53 13 61 9 20 -12 -43 -23 -98 -21 -111 3 -35 67 32 176 104 169
25 -2 32 -8 34 -30z m162 14 c0 -30 -11 -33 -31 -9 -17 21 -19 21 -35 5 -16
-15 -15 -19 15 -55 17 -20 31 -47 31 -59 0 -28 -40 -54 -84 -54 -38 0 -49 9
-37 28 6 10 13 11 35 1 22 -10 29 -10 42 3 14 14 13 19 -15 51 -37 41 -39 70
-9 91 32 23 88 21 88 -2z m191 -3 c34 -64 -32 -169 -106 -169 -75 0 -74 110 1
169 36 28 90 28 105 0z m119 -11 c0 -18 -5 -51 -10 -72 -12 -54 -3 -63 30 -31
25 24 47 66 64 118 5 16 36 25 36 10 0 -5 -6 -40 -14 -80 -7 -40 -11 -75 -8
-78 3 -3 16 2 29 10 13 9 23 11 23 6 0 -15 -42 -41 -67 -41 -20 0 -23 5 -23
37 l0 38 -22 -33 c-23 -33 -58 -49 -77 -37 -13 8 -14 56 -1 109 11 50 4 61
-26 41 -13 -8 -24 -11 -24 -6 0 15 42 41 67 41 19 0 23 -5 23 -32z m240 -4 l0
-35 34 35 c22 24 42 36 60 36 43 0 27 -32 -18 -36 -30 -2 -85 -72 -92 -116 -5
-28 -11 -38 -25 -38 -22 0 -23 5 -4 90 7 35 11 68 9 72 -3 4 -17 0 -32 -9
l-27 -17 24 27 c14 15 35 27 48 27 20 0 23 -5 23 -36z m280 19 c0 -10 -4 -24
-9 -31 -6 -10 -10 -9 -14 7 -14 54 -79 26 -97 -41 -8 -29 -9 -48 -1 -65 12
-26 21 -27 70 -13 45 13 51 6 12 -14 -43 -23 -98 -21 -111 3 -37 70 29 171
111 171 31 0 39 -4 39 -17z m178 -11 c5 -33 -34 -71 -80 -78 -41 -7 -48 -23
-22 -48 18 -19 19 -19 63 -6 45 13 51 6 12 -14 -64 -34 -121 -12 -121 45 0 66
60 132 114 127 24 -2 32 -9 34 -26z m172 10 c0 -30 -11 -33 -31 -9 -17 21 -19
21 -35 5 -16 -16 -15 -19 14 -53 18 -20 32 -47 32 -60 0 -31 -35 -55 -82 -55
-40 0 -51 9 -39 28 6 10 13 11 35 1 22 -10 29 -10 42 3 14 14 13 19 -15 51
-37 41 -39 70 -9 91 32 23 88 21 88 -2z m-5340 -128 c11 -12 10 -18 -3 -32
-16 -15 -18 -15 -34 0 -13 14 -14 20 -3 32 7 9 16 16 20 16 4 0 13 -7 20 -16z
m100 0 c11 -12 10 -18 -3 -32 -16 -15 -18 -15 -34 0 -13 14 -14 20 -3 32 7 9
16 16 20 16 4 0 13 -7 20 -16z m100 0 c11 -12 10 -18 -3 -32 -16 -15 -18 -15
-34 0 -13 14 -14 20 -3 32 7 9 16 16 20 16 4 0 13 -7 20 -16z"/>
<path d="M15555 5976 c-32 -32 -45 -85 -45 -186 0 -102 12 -149 44 -179 77
-72 146 18 146 188 0 127 -35 201 -95 201 -14 0 -37 -11 -50 -24z"/>
<path d="M12372 5978 c-11 -13 -25 -35 -31 -50 -32 -86 -19 -266 24 -316 30
-34 89 -37 115 -4 59 72 50 333 -13 377 -31 22 -70 19 -95 -7z"/>
<path d="M14090 5987 c-44 -21 -60 -72 -61 -188 0 -119 14 -173 53 -202 38
-28 85 -14 112 33 25 42 35 210 17 277 -20 73 -69 106 -121 80z"/>
<path d="M16443 5985 c-40 -28 -56 -93 -51 -212 4 -123 19 -163 68 -183 40
-17 73 -2 97 42 22 43 31 220 13 284 -20 72 -78 103 -127 69z"/>
<path d="M17857 5979 c-35 -27 -49 -84 -49 -196 1 -70 6 -103 21 -138 34 -77
105 -85 145 -15 25 43 35 210 16 279 -22 82 -80 113 -133 70z"/>
<path d="M13176 4288 c-9 -12 -16 -35 -16 -50 0 -28 1 -28 61 -28 l61 0 -7 33
c-12 53 -23 67 -55 67 -19 0 -34 -8 -44 -22z"/>
<path d="M14844 4259 c-16 -18 -19 -37 -19 -114 0 -101 15 -135 60 -135 45 0
60 34 60 135 0 77 -3 96 -19 114 -24 27 -58 27 -82 0z"/>
<path d="M12013 3455 c-43 -30 -54 -135 -17 -149 57 -22 112 122 60 156 -15
11 -19 10 -43 -7z"/>
<path d="M12708 3440 c-14 -11 -28 -29 -31 -40 -4 -17 -2 -19 21 -14 36 9 66
38 59 58 -8 21 -19 20 -49 -4z"/>
<path d="M13628 3441 c-13 -11 -30 -38 -37 -61 -20 -69 5 -80 61 -26 20 19 40
48 46 64 9 27 8 31 -11 36 -31 8 -32 8 -59 -13z"/>
<path d="M13659 3334 c-13 -15 -39 -33 -56 -40 -18 -8 -40 -26 -50 -40 -16
-26 -16 -27 6 -45 59 -48 106 -5 126 114 3 20 4 37 2 37 -2 0 -14 -12 -28 -26z"/>
<path d="M14186 3445 c-39 -42 -44 -126 -9 -140 56 -21 110 124 59 157 -18 12
-26 9 -50 -17z"/>
<path d="M14906 3445 c-40 -43 -45 -125 -9 -139 56 -22 111 122 59 156 -18 12
-26 9 -50 -17z"/>
<path d="M15428 3440 c-14 -11 -28 -29 -31 -40 -4 -17 -2 -19 21 -14 36 9 66
38 59 58 -8 21 -19 20 -49 -4z"/>
<path d="M15766 3445 c-40 -43 -45 -125 -9 -139 56 -22 111 122 59 156 -18 12
-26 9 -50 -17z"/>
<path d="M16598 3440 c-14 -11 -28 -29 -31 -40 -4 -17 -2 -19 21 -14 36 9 66
38 59 58 -8 21 -19 20 -49 -4z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
